body, html {
  padding: 0;
  overflow-x: hidden;
  max-width: 100%;
}

body {
  padding: 0;
  margin: 0;
  max-width: 100%;
  background-color: whitesmoke;
}

h1.title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #3a3873;
  font-size: 31px;
  max-width: 90vw;
  padding: 0;
  margin: 0;
}

h1.subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: whitesmoke;
  font-size: 26px;
  max-width: 90vw;
  padding: 0;
  margin: 0;
}

p.context {
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  font-size: 18px;
  font-weight: 400;
  max-width: 90vw;
  padding: 0;
  margin: 0;
}

.dynamo__dynamic__form__field__submit {
  background-color: #3a3873 !important;
  color: white;
  border-radius: .25rem;
  font-family: Avenir, "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
}

.dynamo__dynamic__form__field__submit {
  background-color: whitesmoke !important;
  color: white;
  border-radius: .25rem;
  font-family: Avenir, "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
}

.dynamo__list-menu__list-menu-item__button {
  width: 100%;
  //background-color: white !important;
}

/* SkillBase style wrapper */

.dynamic {

  &__color {
    color: #3a3873;
    background-color: whitesmoke;
  }

  &__tile {
    width: fit-content;
    height: fit-content;
    background-color: white;
    color: #3a3873;
    padding: 1rem;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  &__text {
    &--bold {
      font-weight: 500 !important;
    }
  }

  &__no-shadow {
    box-shadow: none !important;
  }

  &__background {
    &--transparent {
      background-color: transparent !important;
    }
  }

  &__button {
    color: #3a3873;
    background-color: whitesmoke;
    padding: .25rem .5rem .25rem .5rem;
    border-radius: .5rem;
    width: fit-content;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: .25rem;
    justify-content: center;
    align-items: center;
    border: 1px solid #3a3873;

    &--active {
      color: white !important;
      background-color: #3a3873 !important;
    }
  }

  &__position {
    &--relative {
      position: relative !important;
    }

    &--absolute {
      position: absolute !important;
    }

    &--fixed {
      position: fixed !important;
    }
  }

  &__padding {
    &--lg {
      padding: 3rem !important;
    }

    &--md {
      padding: 2rem !important;
    }

    &--sm {
      padding: 1rem !important;
    }

    &--xs {
      padding: .4rem !important;
    }

    &--none {
      padding: 0 !important;
    }

    &--vertical--lg {
      padding: 3rem 0 3rem 0 !important;
    }

    &--vertical--md {
      padding: 2rem 0 2rem 0 !important;
    }

    &--vertical--sm {
      padding: 1rem 0 1rem 0 !important;
    }

    &--vertical--xs {
      padding: .4rem 0 .4rem 0 !important;
    }

    &--horizontal--lg {
      padding: 0 3rem 0 3rem !important;
    }

    &--horizontal--md {
      padding: 0 2rem 0 2rem !important;
    }

    &--horizontal--sm {
      padding: 0 1rem 0 1rem !important;
    }

    &--horizontal--xs {
      padding: 0 .4rem 0 .4rem !important;
    }
  }

  &__margin {
    &--none {
      margin: 0 !important;
    }

    &--top {
      &-lg {
        margin: 3rem 0 0 0 !important;
      }

      &-md {
        margin: 2rem 0 0 0 !important;
      }

      &-sm {
        margin: 1rem 0 0 0 !important;
      }
    }
  }

  &__width-min {
    &--none {
      min-width: fit-content !important;
    }

    &--full {
      min-width: 100% !important;
    }
  }

  &__title {
    margin: 0;
    font-family: 'Lulo Clean', 'Avenir', 'Montserrat', sans-serif;
    color: black;
    font-size: 50px;
    text-align: center;
    max-width: 90vw;
    word-break: break-word;
    text-transform: uppercase;
    letter-spacing: 4px;

    @media(max-width: 840px) {
      font-size: 40px;
    }

    &--sm {
      margin: 0;
      font-family: 'Avenir', 'Montserrat', sans-serif;
      color: black;
      text-align: center;
      font-size: 40px;
      max-width: 90vw;
      word-break: break-word;
      text-transform: uppercase;
      letter-spacing: 4px;

      @media(max-width: 840px) {
        font-size: 32px;
      }
    }
  }

  &__text {
    margin: 0;
    font-family: 'Avenir', 'Montserrat', sans-serif;
    color: black;
    text-align: start;
    font-size: 25px;
    max-width: 80vw;
    word-break: break-word;

    &--sm {
      margin: 0;
      font-family: 'Avenir', 'Montserrat', sans-serif;
      color: black;
      font-size: 20px;
      max-width: 80vw;
      word-break: break-word;
    }

    &--xs {
      margin: 0;
      font-family: 'Avenir', 'Montserrat', sans-serif;
      color: black;
      font-size: 16px;
      max-width: 80vw;
      word-break: break-word;
    }

    &--xxs {
      margin: 0;
      font-family: 'Avenir', 'Montserrat', sans-serif;
      color: black;
      font-size: 12px;
      max-width: 80vw;
      word-break: break-word;
    }
  }

  &__list-view {
    &__text {
      &--sm {
        font-size: 14px;
      }
    }
  }

  &__icon {
    &__sm {
      font-size: 1.3rem;
      height: 1.3rem;
      width: 1.3rem;
      vertical-align: -26% !important;
    }

    &--active {

    }
  }

  &__tile {
    height: 100%;
    background-color: white;
    color: #3a3873;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-width: 300px;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: .5rem;
  }

  &__stretch {
    width: 100%;
    height: 100%;

    &--height {
      height: 100% !important;
    }

    &--width {
      width: 100% !important;
    }
  }

  &__stretch--view {
    width: 100vw;
    height: 100vh;
  }

  &__view {
    &--height {
      &-fit-content {
        height: fit-content !important;
      }
    }

    &--width {
      &-fit-content {
        width: fit-content !important;
      }
    }
  }

  &__opacity {
    &--less {
      opacity: .8 !important;
    }

    &--full {
      opacity: 1 !important;
    }
  }

  &__element {
    &--highlight {
      background-color: #3a3873 !important;
      color: whitesmoke !important;
    }

    &--pointer {
      cursor: pointer;
    }
  }

  &__divider {
    &--vertical {
      width: 1px;
      height: 100%;
      background-color: #3a3873;
    }

    &--horizontal {
      display: block;
      width: 100%;
      height: .01rem;
      opacity: .3;
      margin: .5rem 0 .5rem 0 !important;
      background-color: #777777;
    }
  }

  @media(max-width: 800px) {
    &__tile {
      width: 80vw;
      min-width: 80vw;
      max-width: 80vw;
    }
  }
}

.mat-menu-panel {
  max-width: none !important;
}

/* */

.mdc-text-field {
  padding: 0 4px !important;
  overflow: visible !important;
}

